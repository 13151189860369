import {Tooltip} from '@radix-ui/themes'
import {Heading, Text} from '@/components'
import {externalLinks} from '@/constants/externalLinks'
import {articles} from '@/data'
import {useAnalytics} from '@/hooks'
import {App} from '@/layout'
import {Grid2X2} from 'lucide-react'
import Link from 'next/link'

import {Article, ArticlesTable} from './components'
import * as S from './styles'

const SEO = {
  title: 'Articles // Vinnicius Gomes',
  canonical: 'https://vinniciusgomes.dev/articles',
}

const PAGE = {
  title: 'Writing about development, business, and personal life experiences.',
  subtitle: (
    <Text size="medium" as="span">
      Here you can find all the <em>{articles.length} articles</em> I wrote. You
      can read about web development, software engineering, and tech career in
      Portuguese. Do you want to receive a notification every time I make a new
      post?{' '}
      <Tooltip content="Open Medium">
        <Link href={externalLinks.blog_subscribe_link} target="_blank">
          Subscribe here!
        </Link>
      </Tooltip>
    </Text>
  ),
}

export default function ArticlesPage() {
  const {trackEvent} = useAnalytics()

  return (
    <App seo={SEO} page={PAGE}>
      <div>
        <Heading level={6} as="h3">
          Featured articles
        </Heading>
        <S.ArticlesWrapper>
          <Article data={articles[0]} key={articles[0].id} isNew/>
          <Article data={articles[1]} key={articles[1].id}/>
        </S.ArticlesWrapper>
      </div>

      <div>
        <S.TitleWrapper>
          <Heading level={6} as="h4">
            All articles
          </Heading>

          <Text size="small">
            <Link
              href="/articles/all"
              onClick={() => trackEvent('articles.click.grid')}
            >
              <Grid2X2 size={14}/>
              Grid view
            </Link>
          </Text>
        </S.TitleWrapper>
        <ArticlesTable data={articles}/>
      </div>
    </App>
  )
}
